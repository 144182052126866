.stp section {
    height: 500vh;
    position: relative;
}

.stp .pin {
    position: relative;
    height: 100vh;
}

.stp .plan {
    position: absolute;
    top:0;
}

.stp .plan-step h5 {
    font-family: 'Roboto Flex';
    font-weight: 900;
    font-size: 20px;
}

.stp .plan-step p {
    font-family: 'Roboto Flex';
    font-weight: 500;
    font-size: 18px;
}

@media only screen and (max-width: 575px) {
    #STEPS .go-classic-btn {
        bottom:50px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        padding:10vw;
        bottom:150px;
    }
    
    .stp .plan-step img{
        width: 120px;
    }
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    #STEPS .go-classic-btn {
        bottom:50px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        padding:10vw;
        bottom:150px;
    }
    
    .stp .plan-step img{
        width: 120px;
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    #STEPS .go-classic-btn {
        bottom:50px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
        padding:10vw;
        bottom:150px;
    }
    
    .stp .plan-step img{
        width: 120px;
        margin-right:2rem;
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    #STEPS .go-classic-btn {
        bottom:50px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
        padding:10vw;
        bottom:150px;
    }
    
    .stp .plan-step img{
        width: 120px;
        margin-right:2rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1399px) {
    #STEPS .go-classic-btn {
        bottom:50px;
        right: 150px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        z-index: 2;
        max-width: 70vw;
        bottom:50px;
        left:50px;
    }
    
    .stp .plan-step img{
        width: 120px;
        margin-right:2rem;
    }
}

@media only screen and (min-width: 1400px) and (max-width:1799px) {
    #STEPS .go-classic-btn {
        bottom:50px;
        right: 150px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        z-index: 2;
        max-width: 70vw;
        bottom:50px;
        left:100px;
    }
    
    .stp .plan-step img{
        width: 120px;
        margin-right:2rem;
    }
}

@media only screen and (min-width: 1800px) and (max-width:2399px) {
    #STEPS .go-classic-btn {
        bottom:50px;
        right: 150px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        z-index: 2;
        max-width: 50vw;
        bottom:300px;
        left:400px;
    }
    
    .stp .plan-step img{
        width: 120px;
        margin-right:2rem;
    }
}

@media only screen and (min-width: 2400px) {
    #STEPS .go-classic-btn {
        bottom:50px;
        right: 150px;
    }
    .stp .pin {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100vh;
    }
    .stp .plan-step {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        z-index: 2;
        max-width: 50vw;
        bottom:50px;
        left:400px;
    }
    
    .stp .plan-step img{
        width: 120px;
        margin-right:2rem;
    }
}